.innerHero {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: flex-start;
	article {
		&.heroInfo {
			background: rgba(9, 46, 110, 0.23);
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			padding: 20px;
			box-sizing: border-box;
			color: $primary;
			p {
				color: $primary;
				margin: 30px 0 20px;
				line-height: 1.3rem;
			}
			.innerStream {
				margin: 20px 0 0;
				// border-bottom: 1px solid $primary;
				// margin-bottom: 20px;
				// padding-bottom: 30px;
				// h3 {
				// 	flex: 0 0 100%;
				// 	text-align: center;
				// }
				.watch {
					font-weight: bold;
					font-family: gothamBold;
					margin: 20px 0 5px;
				}
				a {
					height: auto;
					display: inline-block;
					margin: 5px 20px 0 0;
					vertical-align: middle;
					img {
						margin: 0;
						width: auto;
						vertical-align: middle;
						&[alt="Peacock"] {
							height: 30px;
						}
						&[alt="Hulu"] {
							height: 25px;
						}
						&[alt="Netflix"] {
							height: 25px;
						}
						&[alt="Apple TV"] {
							height: 35px;
						}
					}
				}
			}
			.heroLogo {
				max-width: 80%;
				max-height: 150px;
				margin: 0 auto;
				text-align: center;
				display: block;
			}
			cite {
				font-style: normal;
				margin-bottom: 10px;
				display: block;
				font-weight: bold;
				font-size: 0.8rem;
				line-height: 24px;
				font-family: "gothamBold", "arial", sans-serif;
				span {
					display: block;
					font-family: "gothamLight", "arial", sans-serif;
				}
			}
		}
		&.heroImage {
			width: 100%;
			padding-top: 56.25%;
			display: none;
			justify-content: center;
			background: no-repeat scroll left / cover;
			&.mobileHero {
				display: flex;
			}
			.copyright {
				max-width: 100%;
				font-size: 0.5rem;
				letter-spacing: 0.5px;
				color: white;
				padding: 20px;
				text-align: center;
				text-transform: uppercase;
			}
		}
	}
}
.epic-tales-of-captain-underpants {
	.innerHero {
		article {
			&.heroImage {
				.copyright {
					color: #6F6F6F;
				}
			}
		}
	}
}
.madagascar-a-little-wild {
	.considerationInfo {
		h5 {
			display: none;
		}
	}
}
.item  {
	width: 100%;
	border: none;
	background: none;
	padding: 20px;
	box-sizing: border-box;
	&:active,
	&:focus {
		outline: none;
		border: none;
		box-shadow: none;
	}
	.itemThumb {
		position: relative;
	}
	img {
		width: 100%;
	}
	p {
		text-align: left;
		line-height: 1.2rem;
		text-transform: normal;
	}
	h3 {
		font-size: 1rem;
	}
}
.innerItems {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-direction: column;
	flex-wrap: wrap;
	margin: 0;
	h2 {
		flex: 0 0 100%;
		text-align: center;
		width: 100%;
		font-size: 2rem;
		margin: 20px 0 10px;
	}
}
.innerConsideration {
	background: $primary;
	color: white;
	padding: 30px;
	text-align: center;
	box-sizing: border-box;
	h5, h3 {
		color: white;
		font-weight: normal;
	}
	h5 {
		text-transform: uppercase;
		text-align: justify;
		text-justify: inter-character;
		margin: 10px 0 5px;
		font-size: 0.6rem;
		line-height: 16px;
		display: flex;
		justify-content: space-between;
	}
	h3 {
		font-size: 1.4rem;
		margin: 0 auto 10px;
		white-space: pre-line;
	}
	p {
		color: white;
		margin: 0 0 20px;
		font-weight: lighter;
		font-size: 0.7rem;
	}
	img {
		width: 100%;
	}
}
.innerGallery {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background: no-repeat scroll center / cover;
	position: relative;
	min-height: 400px;
	&:before {
		content: "";
		display: none;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(9, 46, 110, 0.4);
		mix-blend-mode: multiply;
		z-index: 1;
	}
	h3 {
		letter-spacing: 5px;
		font-weight: bold;
		font-size: 1.6rem;
		text-transform: uppercase;
		color: white;
		margin-top: 0;
	}
	.galleryInfo {
		position: relative;
		z-index: 2;
		text-align: center;
	}
}
.innerSecondary {
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	margin-top: 10px;
	.btn {
		background: none;
		border: 1px solid white;
		color: white;
		margin: 0 auto;
		line-height: 50px;
		padding: 0 20px;
		text-transform: uppercase;
		letter-spacing: 3px;
		width: 200px;
		font-size: 0.7rem;
		font-family: "gothamBold", "arial", sans-serif;
		transition: all 0.3s ease-in-out;
		&:hover {
			background: rgba(255,255,255,0.6);
			color: $primary;
			transition: all 0.3s ease-in-out;
		}
	}
	&.first {
		flex-direction: column;
		.innerConsideration {
			width: 100%;
			margin: 0;
		}
		.innerGallery {
			width: 100%;
			margin: 0;
		}
	}
	&.second {
		flex-wrap: wrap;
		.innerConsideration {
			flex: 0 0 100%;
			flex-direction: column;
			display: flex;
			justify-content: center;
			align-items: stretch;
			margin-bottom: 30px;
			img {
				width: 100%;
			}
			.considerationInfo {
				width: 100%;
				box-sizing: border-box;
				text-align: center;

			}
		}
		.innerBonus {
			width: 100%;
			.item {
				padding: 20px;
				h3 {
					text-align: left;
				}
			}
		}
		.innerGallery {
			width: 100%;
		}
	}
	&.third {
		flex-direction: column;
		.innerBonus {
			margin: 0;
			.item {
				margin: 0;
				padding: 20px;
			}
		}
	}
	&.fourth {
		.innerGallery {
			width: 100%;
		}
	}
}
.projectModal {
	background: rgba(9, 46, 110, 0.8);
	position: fixed;
	z-index: 9999;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-wrap: nowrap;
	backdrop-filter: blur(3px);
	-webkit-backdrop-filter: blur(3px);
	opacity: 0;
	transition: all 1s ease-in-out;
	pointer-events: none;
	iframe {
		transform: translate(0,50vh);
		transition: all 0.5s ease-in-out;
	}
	&.show {
		pointer-events: auto;
		opacity: 1;
		transition: all 1s ease-in-out;
		iframe {
			transform: translate(0,0);
			transition: all 0.5s ease-in-out;
		}
	}
	#gallery {
		width: 100%;
		height: auto;
		display: none;
		overflow-x: hidden;
		background: white;
		padding: 20px;
		box-sizing: border-box;
		&.show {
			display: block;
		}
		#galleryWrap {
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
			overflow: hidden;
		}
		img {
			width: 100%;
			height: inherit;
			&.slide {
  				order: 2;
			}
			&.active {
				order: 1;
			}
		}
		nav {
			display: flex;
			justify-content: space-between;
			transform: translate(0,10px);
		}
		.arrows {
			display: flex;
			justify-content: center;
			article[data-arrow="next"] {
				width: 30px;
				height: 30px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				&:before {
					content:"";
					display: block;
					width: 50%;
					height: 2px;
					background: rgba(0,0,0,0.6);
					transform: rotate(45deg);
					transform-origin: right;
					margin: 0 auto;
				}
				&:after {
					content:"";
					display: block;
					width: 50%;
					height: 2px;
					background: rgba(0,0,0,0.6);
					transform: translate(0,-2px) rotate(-45deg);
					transform-origin: right;
					margin: 0 auto;
				}
				&:hover {
					cursor: pointer;
					&:before,
					&:after {
						background: rgba(0,0,0,1);
					}
				}
			}
			article[data-arrow="prev"] {
				width: 30px;
				height: 30px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				&:before {
					content:"";
					display: block;
					width: 50%;
					height: 2px;
					background: rgba(0,0,0,0.6);
					transform: rotate(45deg);
					transform-origin: left;
					margin: 0 auto;
				}
				&:after {
					content:"";
					display: block;
					width: 50%;
					height: 2px;
					background: rgba(0,0,0,0.6);
					transform: translate(0,-2px) rotate(-45deg);
					transform-origin: left;
					margin: 0 auto;
				}
				&:hover {
					cursor: pointer;
					&:before,
					&:after {
						background: rgba(0,0,0,1);
					}
				}
			}
		}
	}
}
@media (min-width: 768px) {
	.projectModal {
		#gallery {
			width: 533px;
			cursor: auto;
		}
	}
	.innerHero {
		article.heroInfo {
			padding: 50px;
			.infoWrap {
				width: 60%;
				margin: 0 auto;
			}
		}
	}
	.innerStream {
		// padding-bottom: 50px;
		// display: flex;
		// justify-content: center;
		// align-items: center;
		// flex-wrap: wrap;
		// a {
		// 	padding: 0 20px;
		// }
	}
	.innerBonus {
		h3 {
			text-align: left;
		}
	}
	.innerItems, {
		margin: 0 -20px;
		flex-direction: row;
		.item {
			width: 33.3%;
		}
	}
	.item {
		.itemThumb {
			.play {
				opacity: 0;
				transform: translate(-50%,-50%) scale(0.5);
			}
		}
		&:hover {
			.play {
				opacity: 1;
				transform: translate(-50%,-50%) scale(1);
				transition: all 0.3s ease-in-out;
			}
		}
	}
	.innerConsideration {
		padding: 40px;
		h5 {
			margin: 5px 0;
		}
		p {
			margin: 0 auto 20px;
		}
	}
	.innerGallery {
		h3 {
			letter-spacing: 10px;
		}
	}
	.innerSecondary {
		&.first {
			flex-direction: row;
			align-items: stretch;
			.innerConsideration {
				width: 50%;
				margin: 0 20px 0 0;
			}
			.innerGallery {
				width: 50%;
				height: auto;
				margin: 0 0 0 20px;
			}
		}
		&.second {
			.innerConsideration {
				flex-direction: row;
				img {
					width: 40%;
				}
				.considerationInfo {
					width: 60%;
					padding: 0 0 0 40px;
					text-align: left;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					align-items: flex-start;
					p {
						margin: 0;
					}
					.btn {
						margin: 0;
					}
				}
			}
			.innerBonus {
				width: 33.3%;
				.item {
					padding: 0 20px 0 0;
				}
			}

		}
		&.third {
			flex-direction: row;
			.innerBonus {
				width: 33.3%;
				.item {
					padding: 0 20px 0 0;
				}
			}
			.innerGallery {
				margin: 0 0 0 20px;
				width: 66.6%;
			}
			.innerConsideration {
				margin: 0 0 0 20px;
				width: 66.6%;
				flex-direction: row;
				display: flex;
				img {
					width: 50%;
					height: auto;
				}
				.considerationInfo {
					width: 50%;
					text-align: left;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					align-items: flex-start;
					padding-left: 30px;
					.btn {
						margin: 0;
					}
				}
			}
		}
	}
}
@media (min-width: 991px) {
	.projectModal {
		#gallery {
			width: 800px;
			padding: 40px;
		}
	}
	.innerHero {
		flex-direction: row;
		align-items: stretch;
		article {
			&.heroInfo {
				width: 40%;
				padding: 40px;
				.infoWrap {
					width: auto;
					p,
					cite {
						font-size: 0.7rem;
						line-height: 1rem;
					}
					.heroLogo {
						max-height: 150px;
					}
				}
			}
			&.heroImage {
				height: auto;
				display: flex;
				padding-top: 46.25%;
				justify-content: flex-end;
				flex-direction: column;
   				align-items: flex-end;
				&.mobileHero {
					display: none;
				}
				.copyright {
					max-width: 100%;
					text-align: left;
				}
			}
		}
	}
	.innerSecondary {
		&.second {
			.innerConsideration {
				h5 {
					justify-content: flex-start;
					span {
						margin: 0 5px;
					}
				}
			}
		}
	}
}
@media (min-width: 1200px) {
	.innerHero {
		article {
			&.heroInfo {
				.infoWrap {
					p,
					cite {
						font-size: 0.75rem;
						line-height: 1.2rem;
					}
					.heroLogo {
						max-height: 150px;
					}
				}
			}
			&.heroImage {
				padding-top: 46.25%;
			}
		}
	}
}
@media (min-width: 1300px) {
	.innerHero {
		article {
			&.heroInfo {
				.infoWrap {
					p,
					cite {
						font-size: 0.8rem;
						line-height: 1.2rem;
					}
				}
			}
		}
	}
}
@media (min-width: 1540px) {
	.innerHero {
		width: 1540px;
		margin: 0 auto;
		article {
			&.heroInfo {
				.infoWrap {
					p,
					cite {
						line-height: 1.4rem;
					}
				}
			}
		}
	}
}